import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import axios from "axios";
import Swal from "sweetalert2";


class WithdrawMoney extends React.Component {

    constructor() {
        super();
        this.state = {
            amount: "",
            displayPopup:"none",
            upi:"",
            mcc:"",
            min_amount:0,
            history:[],
            msgs:[],
            paytm:"",
            phonepe:"none",
            gpay:"none",
            ac:"",
            ifsc:"",
            holder:"",
            paytmView:"",
            phonepeView:"none",
            gpayView:"none",
            bankView:"none",
            withdraw:"",
        }
    }

    componentDidMount() {

        var form = new FormData();
        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));

        axios
            .post(process.env.REACT_APP_URL+"/api2/withdraw_reqs.php", form,
            )
            .then((response) => {
                if(response.status === 200){
                    if (response.data.nosession){
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: "Session Expired"
                        }).then((e)=>{
                            localStorage.clear()
                            window.location.href = "/login";
                        })
                    }
                    localStorage.setItem("wallet", response.data.wallet)
                    this.setState({
                        history:response.data.data, 
                        msgs:response.data.msgs
                    })
                } else if(response.status === 401){
                    //  navigate("/login")
                }
            })
    }


    render() {


        let withdrawSelect = (e)=>{
            if (e === "paytm"){
                this.setState({
                    paytmView:"",
                    phonepeView:"none",
                    gpayView:"none",
                    bankView:"none",
                })
            } else if (e === "gpay"){
                this.setState({
                    paytmView:"none",
                    phonepeView:"none",
                    gpayView:"",
                    bankView:"none",
                })

            } else if (e === "phonepe"){
                this.setState({
                    paytmView:"none",
                    phonepeView:"",
                    gpayView:"none",
                    bankView:"none",
                })
            } else if (e === "bank"){
                this.setState({
                    paytmView:"none",
                    phonepeView:"none",
                    gpayView:"none",
                    bankView:"",
                })
            }
        }

        let apiCall = () =>{

            let error = false;

            let info = "";
            let mode = ""
            if(this.state.paytmView == ""){
                info = this.state.paytm;
                mode = "paytm"
            } else if(this.state.phonepeView == ""){
                mode = "phonepe"
                info = this.state.phonepe;
            } else if(this.state.gpayView == ""){
                mode = "gpay"
                info = this.state.gpay;
            } else if(this.state.bankView == ""){
                mode = "bank"
                info = "A/N - "+this.state.ac+" , IFSC - "+this.state.ifsc+" , NAME - "+this.state.holder;
                    
                if(this.state.ac == undefined || this.state.ac == "" || this.state.ac.length == 0){
                    error = true;
                }
                
                if(this.state.ifsc == undefined || this.state.ifsc == "" || this.state.ifsc.length == 0){
                    error = true;
                }
                
                if(this.state.holder == undefined || this.state.holder == "" || this.state.holder.length == 0){
                    error = true;
                }
            }


            if(this.state.amount == "" || this.state.amount == undefined  || info == "" || info == undefined || info == "none"  || mode == "" || error){
                Swal.fire({
                    toast: true,
                    title: 'Oops...',
                    text: "You must have to fill all details"
                })
            } else {
                var form = new FormData();
                form.append("session", localStorage.getItem("token"));
                form.append("mobile", localStorage.getItem("mobile"));
    
                form.append("amount", this.state.amount);
                form.append("mode", mode);
                form.append("info", info);
    
                axios
                    .post(process.env.REACT_APP_URL+`/api2/withdraw_request.php`,form)
                    .then((response) => {
                        if (response.data.nosession){
                            Swal.fire({
                                toast: true,
                                title: 'Oops...',
                                text: "Session Expired"
                            }).then((e)=>{
                                localStorage.clear()
                                window.location.href = "/login";
                            })
                        }
                        if(response.data.success === "0"){
                            Swal.fire({
                                toast: true,
                                title: 'Oops...',
                                text: response.data.msg,
                            })
                        } else {
                            Swal.fire({
                                toast: true,
                                title: 'Withdraw Request',
                                text: response.data.msg,
                            }).then((e)=>{
                                window.location.href = "/home";
                            })
                        }
                    })
    
            }

          
        }

        return (<>
                <div className="RootView">
                    <div className="RootWidth">

                        <div className="loginDiv">


                            <div className="toolbar">
                                <div>
                                    <img onClick={()=>{window.history.go(-1)}}  src="../images/back.png" style={{padding: "5px"}}/>
                                    <span>Withdraw Money</span>
                                </div>


                            </div>



                            <div style={{padding:"15px"}} className="withdraw-form">

                                <input
                                    style={{width:"100%"}}
                                    className="edittext"
                                    name="amount"
                                    type="number"
                                    value={this.state.amount}
                                    onChange={(e)=>{this.setState({amount:e.target.value})}}
                                    placeholder="Enter Coins"
                                />

                                <select className="form-select edittext" onChange={(e)=>{withdrawSelect(e.target.value)}} style={{marginTop:"15px"}}>
                                    <option value="paytm">Paytm</option>
                                    <option value="gpay">GooglePay</option>
                                    <option value="phonepe">PhonePe</option>
                                    <option value="bank">Bank Details</option>
                                </select>


                                <input
                                    style={{width:"100%", display:this.state.paytmView, marginTop:"15px"}}
                                    className="edittext"
                                    name="amount"
                                    type="number"
                                    value={this.state.paytm}
                                    onChange={(e)=>{this.setState({paytm:e.target.value})}}
                                    placeholder="Enter Paytm Number"
                                />


                                <input
                                    style={{width:"100%", display:this.state.gpayView, marginTop:"15px"}}
                                    className="edittext"
                                    name="amount"
                                    type="number"
                                    value={this.state.gpay}
                                    onChange={(e)=>{this.setState({gpay:e.target.value})}}
                                    placeholder="Enter GPay Number"
                                />


                                <input
                                    style={{width:"100%", display:this.state.phonepeView, marginTop:"15px"}}
                                    className="edittext"
                                    name="amount"
                                    type="number"
                                    value={this.state.phonepe}
                                    onChange={(e)=>{this.setState({phonepe:e.target.value})}}
                                    placeholder="Enter PhonePe Number"
                                />

                                <div style={{display:this.state.bankView}}>
                                    <input
                                        style={{width:"100%", marginTop:"15px"}}
                                        className="edittext"
                                        name="amount"
                                        type="number"
                                        value={this.state.ac}
                                        onChange={(e)=>{this.setState({ac:e.target.value})}}
                                        placeholder="Enter Account Number"
                                    />

                                    <input
                                        style={{width:"100%", marginTop:"15px"}}
                                        className="edittext"
                                        name="amount"
                                        type="text"
                                        value={this.state.ifsc}
                                        onChange={(e)=>{this.setState({ifsc:e.target.value})}}
                                        placeholder="Enter IFSC"
                                    />

                                    <input
                                        style={{width:"100%", marginTop:"15px"}}
                                        className="edittext"
                                        name="amount"
                                        type="text"
                                        value={this.state.holder}
                                        onChange={(e)=>{this.setState({holder:e.target.value})}}
                                        placeholder="Enter Account Holder Name"
                                    />
                                </div>

                                <button className="button" onClick={apiCall}>
                                    <b>Withdraw</b>
                                </button>

                                <div style={{padding:"15px"}}>

                                    { this.state.history && this.state.history.map((result) => {
                                        return (
                                            <div className="transaction_block" key={result.sn}>
                                                <div>
                                                    <span className="">{result.mode+" - "+result.details}</span>
                                                    <span style={{textAlign:"right"}}>{result.date}</span>
                                                </div>
                                                <span>{result.amount}</span>
                                                <span>{result.status}</span>
                                            </div>
                                        )})}

                                </div>

                                
                                <div style={{padding:"15px"}}>

                                    { this.state.msgs && this.state.msgs.map((result) => {
                                        return (
                                            <div  key={result.sn}>
                                              
                                                <p style={{textAlign:"center", border:"solid 1px #00000026", padding:"5px"}}>{result.text}</p>
                                            </div>
                                        )})}

                                </div>


                            </div>

                        </div>


                    </div>
                </div>


            </>
        );
    }

}

export default WithdrawMoney;



