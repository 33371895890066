import React from "react";
import {Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import axios from "axios";
import Swal from "sweetalert2";


class Wallet extends React.Component {

    constructor() {
        super();
        this.state = {
            wallet: "",
            showLoading: "block",
            history:[]
        }
    }

    componentDidMount() {


        var form = new FormData();
        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));

        axios
            .post(process.env.REACT_APP_URL+"/api2/transaction_history.php", form,
            )
            .then((response) => {
                if(response.status === 200){
                    if (response.data.nosession){
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: "Session Expired"
                        }).then((e)=>{
                            localStorage.clear()
                            window.location.href = "/login";
                        })
                    }
                    localStorage.setItem("wallet", response.data.wallet)
                    this.setState({
                        wallet:response.data.wallet,
                        showLoading:"none",
                        history:response.data.data
                    })
                } else if(response.status === 401){
                    //  navigate("/login")
                }
            })
    }


    render() {


        return (<>
                <div className="RootView">
                    <div className="RootWidth">

                        
                    <div style={{display:this.state.showLoading}}>
                        <div class="loader-parent">
                        <div class="loader-block">
                        <div class="loader"></div> 
                        </div>
                        </div>
                    </div>

                        <div className="loginDiv">


                            <div className="toolbar">
                                <div>
                                    <img onClick={()=>{window.history.go(-1)}}  src="../images/back.png" style={{padding: "5px"}}/>
                                    <span>My Wallet</span>
                                </div>

                              

                            </div>

                            <div className="upi_apps">
                                <div className="button" style={{width:"100%", marginLeft:"12px", marginRight:"12px"}}>
                                    <span className="heading" style={{color:"white", borderBottom:"solid 1px #fff", fontSize:"35px", fontWeight:"600"}}>₹ {this.state.wallet}</span>
                                    <span className="subheading" style={{color:"white", fontSize:"15px", fontWeight:"600"}}>Wallet Amount</span>
                                </div>

                            </div>

                            <div className="row homemenu wallet-menu" style={{padding:"3px", marginTop:"12px"}}>
                                        <div className="col-3">
                                            <Link to={"/deposit?mobile="+localStorage.getItem("mobile")}>
                                                <div className="homediv">
                                                    <img src="../images/money (5)-min.png" style={{height:"30px", width:"30px", marginTop:"7px"}} />
                                                    <span>Add<br/>Money</span>
                                                </div>
                                            </Link>
                                        </div>

                                        <div className="col-3">
                                            <Link to={"/depositHistory"}>
                                                <div className="homediv">
                                                    <img src="../images/history (8)-min.png" style={{height:"30px", width:"30px", marginTop:"7px"}} />
                                                    <span>Deposit History</span>
                                                </div>
                                            </Link>
                                        </div>

                                        <div className="col-3">
                                            <Link to={"/withdraw"}>
                                                <div className="homediv">
                                                    <img src="../images/withdrawal (3)-min.png"  style={{height:"30px", width:"30px", marginTop:"7px"}}  />
                                                    <span>Withdraw<br/>Money</span>
                                                </div>
                                            </Link>
                                        </div>

                                        <div className="col-3">
                                            <Link to={"/withdrawHistory"}>
                                                <div className="homediv">
                                                    <img src="../images/history (8)-min.png" style={{height:"30px", width:"30px", marginTop:"7px"}} />
                                                    <span>Withdraw History</span>
                                                </div>
                                            </Link>
                                        </div>
                                      
                                       
                                    </div>

                           
                            <div style={{padding:"15px"}}>
                                <span className="pageTitle" style={{fontSize:"14px"}}>Transactions</span>

                                { this.state.history && this.state.history.map((result) => {
                                    return (
                                        <div className="transaction_block" key={result.sn}>
                                            <span className="date-blocks">{result.date}</span>
                                            <div>
                                                <span className="">{result.remark}</span>
                                                <span className={"trans"+result.type}>{result.amount}</span>
                                            </div>
                                            
                                        </div>
                                    )})}

                            </div>

                        </div>


                    </div>
                </div>


            </>
        );
    }

}

export default Wallet;
