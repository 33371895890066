import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';


const firebaseConfig = {
    apiKey: "AIzaSyA34NsQrvrpf0e05ht6Rbc-_ZstS3wGuZg",
    authDomain: "playwin-8b23a.firebaseapp.com",
    projectId: "playwin-8b23a",
    storageBucket: "playwin-8b23a.appspot.com",
    messagingSenderId: "993162771641",
    appId: "1:993162771641:web:d7c66ff5fd2d1bffd16f68",
    measurementId: "G-4LE10CDE4B"
  };
  



// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);

export { messaging, getToken, onMessage };